import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../../components/layout'
import { startBasicScene } from '../../basic_scene';

import '../styles.css'

const SoftwareProject = ({ data }) => {
  React.useEffect(() => {
    if (!document.querySelector('.background-animation'))
      startBasicScene()
  })
  
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <Link to="../">
        <button className='back-button'>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
        </button>
      </Link>
      <div className="content">
        <h1 className="title">{data.mdx.frontmatter.title}</h1>
        
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        
      </div>
     </Layout>
  );
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
      }
      body
    }
  }
`

export default SoftwareProject